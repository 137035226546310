import { Link } from "@remix-run/react";
import { cn } from "~/utils";

const Logo = ({ className }: { className?: string }) => {
  return (
    <Link to="/" className={cn("block w-14", className)} style={{ viewTransitionName: "logo" }} unstable_viewTransition>
      <img src="/logo.svg" alt="KeenTools logo" className="block w-full" />
    </Link>
  );
};

export default Logo;
